import React from 'react';

import {frontend} from 'pageflow-scrolled/frontend';

import {Logo} from './Logo';
import styles from './footer.module.css';

frontend.widgetTypes.register('builtWithPageflowCreditsBoxFooter', {
  component: function Footer() {
    return (
      <a className={styles.link}
         href="https://www.pageflow.io"
         target="_blank"
         rel="noreferrer noopener">

        <Logo />
        <span className={styles.builtWith}>Built with</span>
        <span className={styles.name}>PAGEFLOW</span>
      </a>
    );
  }
});
