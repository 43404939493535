import React from 'react';

export function Logo() {
  return (
    <svg version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="-50 -50 272.07254 338.90793"
         fill="currentColor">
      <path d="M106.3,238.9l-86.7-86.7C-3.7,129-2,105.3,3.4,89.5c3.8-10.6,9.7-20.2,17.4-28.4l6.5,6.5 C20.5,74.8,15.3,83.4,12,92.8c-6.6,19.3-1.8,37.1,14,52.9l80.2,80.2l39.1-39.1l6.5,6.5L106.3,238.9z"/>
      <path d="M104.2,234.5c-23.2-23.2-21.8-46.8-16.5-62.4c3.6-10.5,9.4-20,17-28.1l42.3-42.3l6.5,6.5l-42.3,42.3 c-6.6,7.2-11.7,15.7-14.9,24.9c-6.3,19-1.5,36.8,14.3,52.6L104.2,234.5z"/>
      <path d="M61.3,193.9c-23.2-23.2-21.8-46.8-16.5-62.4c3.6-10.5,9.4-20,17-28.1l84-84l6.5,6.5l-84,84
      c-6.6,7.2-11.7,15.7-14.9,24.9c-6.4,19.1-1.5,36.8,14.4,52.7L61.3,193.9z"/>
      <path d="M151.6,110.1l-6.5-6.5c6.7-7.2,11.8-15.7,15-25c6.4-19.1,1.6-36.9-14.3-52.7S112.1,5.4,92.6,12.2 C83.1,15.7,74.4,21,67,27.8L25.1,69.7l-6.5-6.5l41.9-41.9c8.3-7.8,18.1-13.8,28.8-17.7c16-5.6,39.9-7.5,63.1,15.8 s21.7,46.8,16.4,62.5C165.1,92.4,159.2,102,151.6,110.1z"/>
      <path d="M151.2,194l-6.5-6.5l3.2,3.2l-3.3-3.2c6.5-7.1,11.5-15.5,14.6-24.6c6.2-18.9,1.3-36.5-14.6-52.4l6.5-6.5 c23.2,23.2,21.9,46.6,16.8,62.1C164.5,176.5,158.7,186,151.2,194z"/>
      <path d="M102.2,69.6"/>
      <path d="M106.8,224.7"/>
    </svg>
  );
}
